<template>
  <div class="login-container">
    <div class="login-box">
      <div class="login-title">
        登录
      </div>
      <div class="login-user">
        <Input class="input" v-model="username" placeholder="请输入用户名"></Input>
      </div>
      <div class="login-passwd">
        <Input class="input" type="password" v-model="password" placeholder="请输入密码"></Input>
      </div>
      <div class="login-commit" @click="login">
        <Button class="login-btn" type="info">登录</Button>
      </div>
    </div>
    <div class="copyright-box">
      Copyright  2022 huiyaopinshang.cn版权所有 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022003530号-2</a>
    </div>
  </div>
</template>

<script>
import api from '@/request/api'
export default {
  data(){
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login(){
      api.login({
        username: this.username,
        password: this.password
      }).then(e => {
        if(e.data.code == 0){
          // this.$Modal.success({
          //   title: '登录成功，即将跳转！',
          //   onOk: ()=> {
          //     this.$router.push({
          //       path: '/user'
          //     })
          //   }
          // });
          this.$Message.success('登录成功！');
          setTimeout(() => {
            this.$router.push({
              path: '/user'
            })
          }, 1000);
          this.$store.commit('updateToken', e.data.data.token)
          console.log(this.$store.state.token)
        }else{
          this.$Modal.error({
            title: '登录失败，请重新登录'
          });
        }
        console.log(e)
      })
    }
  }
}
</script>

<style lang='scss' scope>
  .login-container {
    width: 100vw;
    height: 100vh;
    background: #2d3a4b;
    text-align: center;
    color: #eee;
    position: relative;
    .login-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background: orange;
      width: 400px;
      .login-user, .login-passwd, .login-commit {
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
        .login-btn {
          width: 100%;
        }
      }
      .login-title {
        font-size: 27px;
        font-weight: 700;
        margin-bottom: 40px;
      }
    }
    .copyright-box {
      width: 100%;
      position: absolute;
      bottom: 40px;
      font-size: 13px;
    }
  }
</style>