
<template>
  <div class="layout">
    <Row type="flex" class="row">
      <i-col span="3" class="layout-menu-left">
        <Menu active-name="1-1" theme="dark" width="auto" :open-names="['1']">
          <div class="layout-logo-left">
            <img src="@/assets/logo.png" style="width: 100%;height: 100%">
          </div>
          <Submenu name="1">
            <template slot="title">
              <Icon type="ios-navigate"></Icon>
              用户管理
            </template>
            <Menu-item name="1-1">增删用户</Menu-item>
          </Submenu>
        </Menu>
      </i-col>
      <i-col span="21">
        <div class="layout-header">
          <Button type="info" @click="logout">退出登录</Button>
        </div>
        <div class="layout-breadcrumb">
          <Breadcrumb>
            <Breadcrumb-item href="#">用户管理</Breadcrumb-item>
          </Breadcrumb>
        </div>
        <div class="layout-content">
          <div class="layout-content-main">
            <Row type="flex" class="user-box">
              <Col span="12" class="user-box-inner">
                <Row class="add-user-box">
                  <Button type="info" @click="openEditorPanel('add')">添加用户</Button>
                </Row>
                <Table
                  height="380"
                  border
                  :context="self"
                  :columns="columns"
                  :data="tableData"
                ></Table>
              </Col>
            </Row>
          </div>
        </div>
        <!-- <div class="layout-copy">2011-2016 &copy; TalkingData</div> -->
      </i-col>
      <Modal
        class="modal-box"
          v-model="modal1"
          :title="modalType === 'add' ? '添加用户' : '编辑用户'"
          @on-ok="commit"
          @on-cancel="cancel">
          <Row class="modal-row">
            <Col class="modal-name">用户名</Col>
            <Col class="modal-input">
              <Input :disabled="modalType !== 'add'" v-model="name" placeholder="请输入..."></Input>
            </Col>
          </Row>
          <Row class="modal-row">
            <Col class="modal-name">邀请码</Col>
            <Col class="modal-input">
              <Input  v-model="code" placeholder="请输入..."></Input>
            </Col>
          </Row>
          <Row class="modal-row">
            <Col class="modal-name">过期时间</Col>
            <Col class="modal-input">
              <Date-picker :value="dealTime"  @on-change="dateSelect" type="date" confirm placeholder="选择日期" style="width: 200px"></Date-picker>
            </Col>
          </Row>
      </Modal>
    </Row>
    <div class="copyright-box">
      Copyright  2022 huiyaopinshang.cn版权所有 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022003530号-2</a>
    </div>
  </div>
</template>
<script>
import api from '@/request/api'
export default {
  data() {
    return {
      modal1: false,
      self: this,
      columns: [
        {
          title: "用户名",
          key: "userName",
        },
        {
          title: "邀请码",
          key: "authCode",
        },
        {
          title: "过期时间",
          key: "overDate",
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.show(params.index);
                    },
                  },
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.remove(params.index);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [
      ],
      modalType: 'editor',
      name: '',
      code: '',
      dealTime: '',
      // 当前编辑的index
      activeIndex: ''
    };
  },
  computed: {
    token(){
      return this.$store.state.token
    }
  },
  methods: {
    openEditorPanel(type, index){
      this.activeIndex = index
      this.modalType = type
      this.modal1 = true
      if(type === 'add'){
        this.code = this.randomCode()
      }else{
        this.name = this.tableData[index].userName
        this.code = this.tableData[index].authCode
        this.dealTime = this.tableData[index].overDate
      }
    },
    show(index) {
      // this.$Modal.info({
      //   title: "用户信息",
      //   content: `姓名：${this.tableData[index].name}<br>年龄：${this.tableData[index].age}<br>地址：${this.tableData[index].address}`,
      // });
      this.openEditorPanel('editor',index)
    },
    remove(index) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确定删除该用户？</p>',
        onOk: () => {
          // this.$Message.info('删除');
          let params = {
            id: this.tableData[index].id
          }
          let headers = {
            'Access-Token': this.token
          } 
          api.deleteUser(headers, params).then(e => {
            if(e.data.code === 0){
              this.$Message.success('删除成功！')
              this.getList()
            }else{
              this.$Message.error('删除失败！')
            }
          })
        },
        onCancel: () => {
          // this.$Message.info('取消');
        }
      });
    },
    randomCode(){
      let randomStr = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      let num = 8
      let result = ''
      for(let i=0;i<num;i++){
        result += randomStr[Math.floor(Math.random()*100) % randomStr.length]
      }
      return result
    },
    dateSelect(date){
      this.dealTime = date
      console.log(date)
    },
    commit(){
      console.log(`name: ${this.name}, code: ${this.code}, date: ${this.dealTime}`)
      let headers = {
        'Access-Token': this.token
      }
      let params = {
        authCode: this.code,
        userName: this.name,
        overDate: this.dealTime,
      }
      if(this.modalType !== 'add'){
        params.id = this.tableData[this.activeIndex].id
      }
      api.setUser(headers,params).then(e => {
        console.log('setUser', e, params)
      }).then(e => {
        this.getList()
      })
      this.name = this.code = this.dealTime = ''
    },
    cancel(){
      this.name = this.code = this.dealTime = ''
    },
    logout(){
      this.$router.push({path: '/'})
    },
    getList(){
      api.getUserList({
        'Access-Token': this.token
      }).then(e => {
        console.log('getUserList', e)
        let data = e?.data?.data
        let tableData = []
        if(data.length !== 0){
          data.map(ele => {
            tableData.push({
              userName: ele.userName,
              authCode: ele.authCode,
              overDate: ele.overDate,
              id: ele.id
            })
          })
        }
        this.tableData = JSON.parse(JSON.stringify(tableData))
      })
    }
  },
  mounted(){
    this.getList()
  }
};
</script>

<style scoped lang='scss'>
.layout {
  width: 100vw;
  height: 100vh;
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  // background: orange;
  // overflow: hidden;
  position: relative;
  text-align: left;
  .row {
    width: 100%;
    height: 100%;
  }
  .copyright-box {
    width: 100%;
    position: absolute;
    bottom: 40px;
    font-size: 13px;
    text-align: center;
    // background: cadetblue;
  }
}
.layout-breadcrumb {
  padding: 10px 15px 0;
  text-align: left;
}
.layout-content {
  box-sizing: border-box;
  height: calc(100% - 180px);
  margin: 15px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  // background: cadetblue;
  border-radius: 4px;
}
// 用户配置面板
.layout-content-main {
  padding: 10px;
  height: 100%;
  // background: #fff;
  .user-box {
    // background: cornflowerblue;
    // background: #fff;
    height: 100%;
    .user-box-inner {
      height: 100%;
      // background: #fff;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 10px;
      overflow: hidden;
      // background: #5b6270;
      .add-user-box {
        // width: 100%;
        text-align: right;
        // background: #fff;
        // margin: 10px 0;
        margin-bottom: 20px;
      }
      .table {
      }
    }
  }
}

.layout-copy {
  text-align: center;
  padding: 10px 0 20px;
  color: #9ea7b4;
}
.layout-menu-left {
  background: #464c5b;
}
.layout-header {
  height: 60px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  // background: cadetblue;
  box-sizing: border-box;
  padding-top: 13px;
  text-align: right;
  padding-right: 30px;
}
.layout-logo-left {
  width: 90%;
  height: 30px;
  // background: #5b6270;
  border-radius: 3px;
  margin: 15px auto;
}


// modal
.modal-box {
  .modal-row {
    width: 100%;
    height: 32px;
    // background: cadetblue;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    .modal-name {
      display: inline-block;
      min-width: 50px;
      margin-right: 10px;
      // background: orange;
      line-height: 32px;
    }
    .modal-input {
      flex: 1 1 auto;
      // background: #5b6270;
    }
  }
}
</style>