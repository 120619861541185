const axios = require('axios');
const instance = axios.create({
  baseURL: baseURL,
  timeout: 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // "Cookie": "sessionId=8d1ad046-6967-4704-b562-5758f1a02d59"
  },
  // request use form-data
  transformRequest: [
    function (data) {
       let ret = ''
       for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
       }
       ret = ret.substring(0, ret.lastIndexOf('&'));
       return ret
    }
  ],
});

const api = {
  // 登录
  login(params){
    return instance({
      url: '/login/login',
      method: 'post',
      // formData: params
      // params: params
      data: params,
    })
  },

  // 查询列表
  getUserList(headers, params){
    return instance({
      url: '/admin/hyuser',
      method: 'get',
      params: params,
      headers: headers
    })
  },
  
  // 修改
  setUser(headers, params){
    return instance({
      url: 'admin/hyuser',
      method: 'post',
      data: params,
      headers: headers
    })
  },

  // 删除
  deleteUser(headers, params){
    return instance({
      url: `admin/hyuser/${params.id}`,
      method: 'delete',
      headers: headers
    })
  } 
}

export default api;